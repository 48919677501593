<template>
   <v-container>
    <overlay-photo :overlay="overlay" :stories="day_zoomed && day_zoomed.stories" :index_zoomed="story_zoomed" :removeOverlay="()=>overlay=false"/>
    <v-card class="mb-4">
      <v-card-title>Stories</v-card-title>
      <v-card-text>
        <div class="mt-5" v-for="day in stories" :key="day.date">
          <div>{{day.date}}</div>

          <v-row wrap class="ma-0">
            <v-col style="position:relative;align-self:center" class="col-6 col-sm-3 pa-1" v-for="(story,i) in day.stories" :key="story.image_url" @click="()=>{day_zoomed=day;story_zoomed=i;overlay=true}">
              <v-btn v-if="admin" @click.stop="(e)=>deleteStory(e,day,i)" icon style="position:absolute;top:0px; right:0px; transform:translate(-50%, 0%);z-index:2">
                    <v-icon>delete</v-icon>
              </v-btn>
              <v-img style="cursor:pointer" v-if="story.image_type=='image'" :src="story.thumbnail_url" contain ></v-img>
              <video class="afterglow" style="top: 50%;left: 50%;transform: translate(-50%,0%);position: relative;" v-else :src="story.image_url" width="100%" controls></video>
            </v-col>          
          </v-row>
        </div>
      </v-card-text>
    </v-card>
   </v-container>
</template>

<script>
import {firestore, storage, auth} from '../main'
import {collection, getDocs, deleteDoc, doc, orderBy,query} from 'firebase/firestore'
import {ref, deleteObject} from 'firebase/storage'
import OverlayPhoto from '../components/OverlayPhoto.vue'

export default {
    components:{OverlayPhoto},
    data(){
        return {
            stories:[],
            story_zoomed:null,
            overlay:false,
            day_zoomed:null
        }
    },
    methods:{
      async deleteStory(e,day, ind){
        e.stopPropagation();
        let story = day.stories[ind];
        let doc_story = doc(firestore,"stories",story.firestore_id);
        try {
        await deleteObject(ref(storage, story.image_url));
        if (story.thumbnail_url && story.thumbnail_url.length > 0)
          await deleteObject(ref(storage, story.thumbnail_url));
        } catch(e) {console.log(e)}
        await deleteDoc(doc_story);
        day.stories.splice(ind,1);
      }
    },
    computed:{
      phone(){
        return this.$vuetify.breakpoint.name === "xs"
      },
      auth(){return auth}
    },
    async created(){
        let idToken = await this.auth.currentUser.getIdTokenResult();
        this.admin = !!idToken.claims.admin && idToken.claims.admin;
        let stories = await getDocs(query(collection(firestore, "stories"),orderBy("date","desc")));
        stories = stories.docs.map((snap)=>{return {...snap.data(), date_name:new Date(snap.data().date.seconds*1000).toLocaleDateString("fr-FR"),firestore_id:snap.id}});
        if (stories.length == 0)
          return
        let current_date = stories[0].date_name;
        
        while (stories.length>0){
          let new_index = stories.findIndex(story=>story.date_name != current_date);
          if (new_index == -1) {
            this.stories.push({date:current_date, stories:stories});
            stories = []
          }
          else {
            this.stories.push({date:current_date,stories:stories.splice(0,new_index)});
            current_date = stories[0].date_name
          }
        }

    }
}
</script>

