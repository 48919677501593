<template>
  <v-container>
    <v-overlay v-if="overlay">
        <v-card light>
            <v-card-text>
                <v-select label="Pays" v-model="country" :disabled="countries.length==0" :items="countries" item-text="name" item-value="firestore_id" outlined @change="countryChosen"></v-select>
                <v-select label="Lieux" chips multiple v-model="places_chosen" :disabled="places.length==0" :items="places" item-text="name" item-value="firestore_id" outlined @change="e=>console.log(e)"></v-select>
                <v-date-picker
                v-model="date"
                :show-current="false"
                ></v-date-picker>
                <v-text-field label="Lien youtube" v-model="new_youtube" outlined></v-text-field>
                
            </v-card-text>
            <v-card-actions>
                <v-btn @click="addTrip" outlined>Ajouter étape</v-btn>
                <v-btn @click="overlay=false" outlined>Annuler</v-btn>
            </v-card-actions>
        </v-card>
    </v-overlay>
    <v-overlay v-if="youtube">
        <v-btn style="position:absolute;z-index:3;top:-10%;right:-10%" @click="()=>youtube=null" icon>
            <v-icon>close</v-icon>
        </v-btn>
        <v-card>
            <iframe style="width:min(80vw, 140vh);height:min(45vw,80vh)"
            :src="youtube" 
            title="YouTube video player" frameborder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
            allowfullscreen></iframe>
        </v-card>
    </v-overlay>
    <v-layout v-if="admin" justify-center>
        <v-flex xs4 class="text-center">
            <v-btn icon large @click="()=>{getCountries();overlay=true}">
                <v-icon>add</v-icon>
            </v-btn>
        </v-flex>
    </v-layout>
    <v-layout justify-center>
        
        <v-flex xs12 md9 style="position:relative" class="text">
             <div style="position:absolute;top:0px;bottom:-10px; left:50%;width:0px;border: 2px solid #5D8276;border-radius:2px;transform:translate(-50%)"></div>
            
            <v-layout v-for="trip in trips" :key="trip.id" class="py-5" align-center style="min-height:130px">
                <v-flex xs5 class="text-center pr-2 pr-md-0">
                    <router-link class="font-weight-bold text" :to="`/continent/${trip.continent_id}/pays/${trip.country_id}`">{{trip.name}}</router-link>
                    <div>{{trip.date.substr(0,7)}}</div>
                    <v-btn red v-if="trip.youtube" @click="()=>youtube=trip.youtube" large icon><v-icon color="red">play_circle</v-icon></v-btn>

                </v-flex>
                <v-flex xs2 class="text-center d-flex justify-center" style="position:relative">
                    <v-avatar :size="phone ? 75 : 100">
                        <v-img :src="trip.thumbnail_url">
                        </v-img>
                    </v-avatar>
                    <v-btn v-if="admin" @click="()=>deleteTrip(trip)" icon absolute style="bottom:0px;left:0px"><v-icon>delete</v-icon></v-btn>
                </v-flex>
                <v-flex xs5 class="text-center pl-2 pl-md-0" style="white-space: pre-line;"><router-link v-for="place in trip.places" class="text" :key="place.firestore_id" link :to="`/continent/${trip.continent_id}/pays/${trip.country_id}/lieu/${place.firestore_id}`">{{place.name}}<br/></router-link></v-flex>
            </v-layout>

        </v-flex>
    </v-layout>
      
  </v-container>
</template>

<script>
import {firestore, storage, auth} from '../main'
import {getDocs, collection, getDoc, doc, updateDoc, arrayUnion} from 'firebase/firestore';
import {getBytes, uploadBytes, ref, getDownloadURL, deleteObject } from 'firebase/storage';

export default {
    data() {
        return {
            trips:[],
            countries:[],
            places:[],
            overlay:false,
            date:null,
            country:null,
            places_chosen:[],
            admin:false,
            youtube:null,
            new_youtube:null
        }
    },
    computed:{
            phone() {
                return this.$vuetify.breakpoint.name=="xs"
            },
            console() {
                return console;
            }
    },
    methods: {
        async countryChosen(id) {
            let country = this.countries.find((country)=>{return country.firestore_id == id});
            let places = await getDocs(collection(firestore,"continents",country.continent_id,"pays",id,"lieux"));
            this.places = [];
            places.forEach(place=>{
                let data = place.data(); 
                this.places.push({name:data.name,firestore_id:place.id,country_id:id,continent_id:country.continent_id,thumbnail_url:data.thumbnail_url})
            });
        },
        async getCountries() {
            let continents = await getDocs(collection(firestore,"continents"));
            continents.forEach(async snap=>{
                let countries = await getDocs(collection(firestore,"continents",snap.id,"pays"));
                countries.forEach(country=>{
                    this.countries.push({continent_id:snap.id,firestore_id:country.id,name:country.data().name,thumbnail_url:country.data().thumbnail_url})
                })
            });
        },
        async addTrip() {
            
            let country = this.countries.find((country)=>{return country.firestore_id == this.country});
            let trip = {name: country.name, country_id:this.country,continent_id:country.continent_id, date:this.date,places:[]};
            if (this.new_youtube && this.new_youtube.length > 0) {
                if (this.new_youtube.includes("embed"))
                    trip.youtube = this.new_youtube;
                else {
                    if (this.new_youtube.includes("youtu.be")) {
                        let youtube_end = this.new_youtube.split('/')[this.new_youtube.split('/').length-1]
                        trip.youtube = "https://www.youtube.com/embed/" + youtube_end
                    }
                    else {
                        let youtube_end = this.new_youtube.split('/')[this.new_youtube.split('/').length-1]
                        let s = youtube_end.indexOf("v=")+2
                        let e = youtube_end.indexOf("&",s)
                        if(e == -1)
                            e = youtube_end.length
                        trip.youtube = "https://www.youtube.com/embed/" + youtube_end.slice(s,e)
                    }
                }
            }
                
            for (let place of this.places_chosen) {
                let p = this.places.find((p)=>{return p.firestore_id == place});
                trip.places.push({name:p.name,firestore_id:p.firestore_id});
            }
            
            let ref_old = ref(storage,this.places.find((p)=>{return p.firestore_id == this.places_chosen[0]}).thumbnail_url);
            if (!!ref_old && ref_old.fullPath != "") {
                let thumbnail_data = await getBytes(ref_old);
                let ref_new = ref(storage,`trips/${Math.round(Math.random()*10000)}-${ref_old.name}`);
                await uploadBytes(ref_new,thumbnail_data);
                trip.thumbnail_url = await getDownloadURL(ref_new);
            }
            await updateDoc(doc(firestore,"stats","trips"), {trips:arrayUnion(trip)});
            this.trips.push(trip);
            this.trips = this.trips.sort((a,b)=>b.date.localeCompare(a.date));
            this.overlay = false;
        },
        async deleteTrip(trip) {
            if (trip.thumbnail_url && trip.thumbnail_url.length > 0)
                await deleteObject(ref(storage,trip.thumbnail_url));
            this.trips.splice(this.trips.indexOf(trip),1);
            await updateDoc(doc(firestore,"stats","trips"), {trips:this.trips});
        }
    },
    async created() {
        let idToken = await auth.currentUser.getIdTokenResult();
        this.admin = !!idToken.claims.admin && idToken.claims.admin;
        this.trips = (await getDoc(doc(firestore,"stats","trips"))).data().trips;
        this.trips = this.trips.sort((a,b)=>b.date.localeCompare(a.date));
        
    }
}
</script>

<style>
.text {
    font-size: 25px;
    line-height: 30px;
    color: #587D71 !important;
    text-decoration: none;
}

@media  (max-width:600px) {
    .text {
    font-size: 18px;
    line-height: 22px;
}


}
</style>